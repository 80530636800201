import { axiosBackendService, axiosMetadataService, cacheService } from '@/common/services'
import {
    AuditCheck,
    DocumentDetails,
    DocumentSummary,
    ExpectedFile,
    Field,
    FieldCriteria,
    FieldDetail,
    FieldFormatDetail,
    FieldFormatSummary,
    FieldSummary,
    FieldTestDataParams,
    FieldTestDataResponse,
    FormRule,
    FormRuleSummary,
    FormDetail,
    ResponseType,
    Rule,
    RuleDetail,
    TestRuleRequest,
    TestRuleResponse,
    LoanRecord,
    FieldTestBulkResponse,
    DefaultRule,
    TestDefaultValueResponse,
    TestDefaultValueRequest,
    ClosingDisclosureType,
    PlacementRule,
    CopiesRule,
    DocumentDetailsPreview,
    SortGroup,
    ImageFile,
    PagedCollection,
    NotaryAcknowledgment,
    HybridPackage,
    HybridPackageDto
} from '@/common/models'
import { LoanAuditCheckResult, Package, PackageDocument } from '@/propel/models'
import moment from 'moment'
import { BlobSaverHelper } from '@/common/utilities/helper'

class MetadataService {
    private cache: Map<string, any> = new Map<string, any>()

    async postField(data: FieldDetail): Promise<FieldDetail> {
        const response = await axiosMetadataService.post(`/fields`, data)
        return response.data
    }

    async getFields(params?: URLSearchParams): Promise<PagedCollection<FieldSummary>> {
        const response = await axiosMetadataService.get('/fields', { params })
        return response.data
    }

    async getField(key: string | null): Promise<FieldDetail> {
        const response = await axiosMetadataService.get(`/fields/${key}`)
        return response.data
    }

    async deleteField(key: string) {
        const response = await axiosMetadataService.delete(`/fields/${key}`)
        return response.data
    }

    async getFieldCriteria(fieldName: string): Promise<FieldCriteria> {
        const response = await axiosMetadataService.get(`/fields/${fieldName}/criteria`)
        return response.data
    }

    async getFieldFormats(params?: URLSearchParams): Promise<FieldFormatSummary> {
        const response = await axiosMetadataService.get(`/field-formats`, { params })
        return response.data
    }

    async getImages(params?: URLSearchParams): Promise<FieldFormatSummary> {
        const response = await axiosMetadataService.get(`/images`, { params })
        return response.data
    }

    async getImageBase64(fileName: string): Promise<any> {
        const response = await axiosMetadataService.get(`/images/${fileName}`)
        return response.data
    }

    async getImageFile(fileName: string): Promise<any> {
        const response = await axiosMetadataService.get(`/images/${fileName}/file`)
        return response.data
    }

    async putImage(data: ImageFile): Promise<ImageFile> {
        const response = await axiosMetadataService.put(`/images`, data)
        return response.data
    }


    async getFieldFormat(id: string | null): Promise<FieldFormatDetail> {
        const response = await axiosMetadataService.get(`/field-formats/${id}`)
        return response.data
    }

    async postFieldFormat(data: FieldFormatDetail): Promise<FieldFormatDetail> {
        const response = await axiosMetadataService.post(`/field-formats`, data)
        return response.data
    }

    async deleteFieldFormat(id: string): Promise<void> {
        await axiosMetadataService.delete(`/field-formats/${id}`)
    }


    async deleteImage(fileName: string): Promise<void> {
        await axiosMetadataService.delete(`/images/${fileName}`)
    }

    async getAllFormRules(params?: URLSearchParams): Promise<PagedCollection<FormRuleSummary[]>> {
        const response = await axiosMetadataService.get('/document-rules', { params })
        return response.data
    }

    async downloadFormRulesCsv() : Promise<any> {
        const response = await axiosMetadataService.get(`/document-rules/csv`)
        const fileName = `Form-Rules-${moment.utc()}.csv`
        BlobSaverHelper.SaveBlob(response.data, response.headers["content-type"], fileName)
    }

    async getFormRules(documentId: string | null): Promise<FormRule[]> {
        const response = await axiosMetadataService.get(`/documents/${documentId}/rules`)
        return response.data
    }

    async getFormRule(documentId: string, formRuleId: string): Promise<FormRule> {
        const response = await axiosMetadataService.get(`/documents/${documentId}/rules/${formRuleId}`)
        return response.data
    }

    async postFormRule(formRule: FormRule): Promise<FormRule> {
        const documentId = formRule.formId
        const response = await axiosMetadataService.post(`/documents/${documentId}/rules`, formRule)
        return response.data
    }

    async deleteFormRule(documentId: string | null, formRuleId: string | null): Promise<void> {
        await axiosMetadataService.delete(`/documents/${documentId}/rules/${formRuleId}`)
    }

    async getAllDocuments(params?: URLSearchParams): Promise<PagedCollection<DocumentSummary>> {
        const response = await axiosMetadataService.get('/documents', { params })
        return response.data
    }

    async getHybridPackage(): Promise<HybridPackage> {
        const response = await axiosMetadataService.get(`/hybrid-packages`)
        return response.data
    }

    async postHybridPackage(hybridPackage: HybridPackageDto): Promise<HybridPackage> {
        const response = await axiosMetadataService.post(`/hybrid-packages`, hybridPackage)
        return response.data
    }

    async getDocument(id: string | null): Promise<DocumentDetails> {
        const response = await axiosMetadataService.get(`/documents/${id}`)
        return response.data
    }
    async postDocument(data: DocumentDetails): Promise<DocumentDetails> {
        const response = await axiosMetadataService.post(`/documents`, data)
        return response.data
    }
    async deleteDocument(id: string): Promise<void> {
        await axiosMetadataService.delete(`/documents/${id}`)
    }
    async getFieldTestResults(params: FieldTestDataParams): Promise<FieldTestDataResponse> {
        const result = {} as FieldTestDataResponse
        try {
            const response = await axiosMetadataService.post('/fields/test-result', params)
            result.type = response.data.messages[0].type
            result.value = response.data.messages[0].value
        }
        catch (error: any) {
            result.type = ResponseType.Error
            if (error.response) {
                result.value = error.response.data
            }
            else {
                result.value = error
            }
        }
        return result
    }
    async getBulkFieldTestResults(id: string | undefined | null): Promise<FieldTestBulkResponse[]> {
        let response: { data: { results: FieldTestBulkResponse[] } }
        if (id) //we have an id, so test that specific loan
            response = await axiosMetadataService.post(`/fields/test-all/${id}`)
        else {
            //we don't have an id, so grab the loan template and do path tests
            const loanRecord = await axiosBackendService.get('/loans/templateview/')
            response = await axiosMetadataService.post('/fields/test-all?pathTest=true', { loanRecord: loanRecord.data })
        }
        return response.data.results
    }

    async getExpectedFiles(params: string): Promise<ExpectedFile[]> {
        const response = await axiosMetadataService.get(`/documents/expected-files?${params}`)
        return response.data
    }
    async getForm(fileName: string): Promise<FormDetail> {
        const response = await axiosMetadataService.get(`/forms/${fileName}`)
        return response.data
    }
    async putForm(data: FormDetail): Promise<DocumentDetails> {
        const response = await axiosMetadataService.put(`/forms`, data)
        return response.data
    }

    async getAllRules(): Promise<Rule[]> {
        const cacheKey = 'ref_metadata_all-rules'
        const params = new URLSearchParams()
        params.append('pageSize', '1000')
        const response = await cacheService.getItem(cacheKey, () => axiosMetadataService.get("/rules", { params }))
        return response.data.items
    }

    async getRules(params?: URLSearchParams): Promise<PagedCollection<Rule>> {
        const response = await axiosMetadataService.get('/rules', { params })
        return response.data
    }

    async getRule(id: string): Promise<RuleDetail> {
        const response = await axiosMetadataService.get(`/rules/${id}`)
        return response.data
    }
    async postRule(rule: RuleDetail): Promise<RuleDetail> {
        cacheService.removeItem('ref_metadata_all-rules')
        const response = await axiosMetadataService.post('rules', rule)
        return response.data
    }
    async deleteRule(id: string): Promise<void> {
        cacheService.removeItem('ref_metadata_all-rules')
        await axiosMetadataService.delete(`/rules/${id}`)
    }
    async testRule(request: TestRuleRequest): Promise<TestRuleResponse> {
        const response = await axiosMetadataService.post('rule-test-results', request)
        return response.data
    }
    async testDefaultValue(fieldId: string, loanId: string, request: TestDefaultValueRequest): Promise<TestDefaultValueResponse> {
        const response = await axiosMetadataService.post(`/default-values/test/field/${fieldId}/loan/${loanId}`, request);
        return response.data;
    }

    async getPackage(loanId: string, packageType: string, isRedraw: boolean): Promise<Package> {
        const response = await axiosMetadataService.get(`/packages?loanId=${loanId}&packageType=${packageType}&isRedraw=${isRedraw}`)
        return response.data as Package
    }

    async getAuditCheck(loanId: string, packageType?: string | null): Promise<LoanAuditCheckResult> {
        const params = new URLSearchParams()
        params.append("loanId", loanId)

        if (packageType) {
            params.append("packageType", packageType)
        }

        const response = await axiosMetadataService.get(`/audit-check-results`, {params})
        return response.data
    }

    async getOptionalDocuments(loanId: string): Promise<PackageDocument[]> {
        const response = await axiosMetadataService.get(`/packages/optional-documents?loanId=${loanId}`)
        return response.data as PackageDocument[]
    }

    async getFieldAuditChecks(fieldId: string | null): Promise<AuditCheck[]> {
        const response = await axiosMetadataService.get(`/fields/${fieldId}/audit-checks`)
        return response.data as AuditCheck[]
    }

    async postFieldAuditCheck(fieldId: string | null, data: AuditCheck): Promise<AuditCheck> {
        const response = await axiosMetadataService.post(`/fields/${fieldId}/audit-checks`, data)
        return response.data
    }

    async deleteAuditCheck(fieldId: string | null, key: string) {
        const response = await axiosMetadataService.delete(`/fields/${fieldId}/audit-checks/${key}`)
        return response.data
    }

    async getSystemTypes(params?: URLSearchParams): Promise<string[]> {
        const response = await axiosMetadataService.get(`/fields/system-types`, { params })
        return response.data
    }

    async getSystemTypeValues(typeName: string | null): Promise<any> {
        const response = await axiosMetadataService.get(`/fields/system-types/${typeName}`)
        return response.data
    }

    async getIndividualAcknowledgments(params?: URLSearchParams): Promise<NotaryAcknowledgment[]> {
        const response = await axiosMetadataService.get('/individual-acknowledgments', { params })
        return response.data
    }

    async getCorporateAcknowledgments(params?: URLSearchParams): Promise<NotaryAcknowledgment[]> {
        const response = await axiosMetadataService.get('/corporate-acknowledgments', { params })
        return response.data
    }

    async postIndividualAcknowledgment(notaryAcknowledgment: NotaryAcknowledgment): Promise<NotaryAcknowledgment> {
        const response = await axiosMetadataService.post('individual-acknowledgments', notaryAcknowledgment)
        return response.data
    }

    async postCorporateAcknowledgment(notaryAcknowledgment: NotaryAcknowledgment): Promise<NotaryAcknowledgment> {
        const response = await axiosMetadataService.post('corporate-acknowledgments', notaryAcknowledgment)
        return response.data
    }

    async deleteIndividualAcknowledgment(id: string): Promise<void> {
        await axiosMetadataService.delete(`/individual-acknowledgments/${id}`)
    }

    async deleteCorporateAcknowledgment(id: string): Promise<void> {
        await axiosMetadataService.delete(`/corporate-acknowledgments/${id}`)
    }

    async getAllJurats(params?: URLSearchParams): Promise<NotaryAcknowledgment[]> {
        const response = await axiosMetadataService.get('/jurats', { params })
        return response.data
    }

    async postJurat(jurat: NotaryAcknowledgment): Promise<NotaryAcknowledgment> {
        const response = await axiosMetadataService.post('jurats', jurat)
        return response.data
    }

    async deleteJurat(id: string): Promise<void> {
        await axiosMetadataService.delete(`/jurats/${id}`)
    }

    async getAllSortGroups(params?: URLSearchParams): Promise<PagedCollection<SortGroup>> {
        const response = await axiosMetadataService.get('/sort-groups', { params })
        return response.data
    }

    async getNextSortGroupPosition(sortGroupId: string, formId?: string): Promise<number> {
        const params = new URLSearchParams()
        if (formId) {
            params.append("formId", formId)
        }
        const response = await axiosMetadataService.get(`sort-groups/${sortGroupId}/next-position`, { params })
        return response.data
    }

    async getSortGroup(id: string): Promise<SortGroup> {
        const response = await axiosMetadataService.get(`/sort-groups/${id}`)
        return response.data
    }
    async postSortGroup(sortGroup: SortGroup): Promise<SortGroup> {
        const response = await axiosMetadataService.post('sort-groups', sortGroup)
        return response.data
    }
    async deleteSortGroup(id: string): Promise<void> {
        await axiosMetadataService.delete(`/sort-groups/${id}`)
    }

    async getAvailablePreviewDocuments(loanRecord: LoanRecord | null, overwriteCache = false): Promise<DocumentDetailsPreview[]> {
        if (loanRecord) {
            const cacheKey = `previewDocuments.${loanRecord.id}
                .${loanRecord.documentMode}
                .${loanRecord.data.documentDatasets?.disclosures?.cashToClose?.disclosureType || ClosingDisclosureType.Standard}
                .${loanRecord.data.property?.address?.state || ''}`
            if (overwriteCache || !this.cache.has(cacheKey)) {
                const response = await axiosMetadataService.post("/packages/preview/documents", { loanRecord })
                this.cache.set(cacheKey, response.data)
            }
            return this.cache.get(cacheKey) as DocumentDetailsPreview[]
        }

        return Promise.resolve([] as DocumentDetailsPreview[])
    }

    async getFieldDefaultValueRules(fieldId: string | null): Promise<DefaultRule[]> {
        const response = await axiosMetadataService.get(`/fields/${fieldId}/default-values`)
        return response.data
    }

    async postFieldDefaultValueRule(fieldId: string | null, defaultRule: DefaultRule): Promise<DefaultRule> {
        const response = await axiosMetadataService.post(`/fields/${fieldId}/default-values`, defaultRule)
        return response.data
    }

    async updateDocumentPlacementRules(documentId: string, placementRules: PlacementRule[]) {
        await axiosMetadataService.patch(`/documents/${documentId}/placement-rules`, placementRules)
    }

    async updateDocumentCopiesRules(documentId: string, copiesRules: CopiesRule[]) {
        await axiosMetadataService.patch(`/documents/${documentId}/copies-rules`, copiesRules)
    }

    async deleteFieldDefaultValueRule(fieldId: string | null, defaultValueId: string | null): Promise<void> {
        await axiosMetadataService.delete(`/fields/${fieldId}/default-values/${defaultValueId}`)
    }

    async getFieldsUnderDevelopment(): Promise<Field[]> {
        const response = await axiosMetadataService.get(`/fields/under-development`)
        return response.data
    }

    async getFieldsForSignatureDates(): Promise<Field[]> {
        const cacheKey = 'ref_metadata_signature-date-fields'
        const response = await cacheService.getItem(cacheKey, () => axiosMetadataService.get("/fields/signature-date-fields"))
        return response.data as Field[]
    }

    async getFieldJsonPaths(): Promise<FieldSummary[]> {
        const params = new URLSearchParams()
        params.append("fieldType", "jsonPath")
        return await this.lookupFields(params)
    }

    async lookupFields(params?: URLSearchParams): Promise<FieldSummary[]> {
        const response = await axiosMetadataService.get('/fields/lookup', { params })
        return response.data
    }

    resetSignatureDateCache() {
        cacheService.removeItem('ref_metadata_signature-date-fields')
    }

    async getFieldDependencyCsv(fieldId: string | null) : Promise<any> {
        const response = await axiosMetadataService.get(`/fields/${fieldId}/dependencies/csv`)
        return response.data
    }

    async downloadDocumentsCsv() : Promise<any> {
        const response = await axiosMetadataService.get(`/documents/csv`)
        const fileName = `Forms-${moment.utc()}.csv`
        BlobSaverHelper.SaveBlob(response.data, response.headers["content-type"], fileName)
    }
}

export const metadataService = new MetadataService()