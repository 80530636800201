import { AuditInfo } from './audit-info';
import { Condition } from './condition';

export interface Field {
    id: string
    name: string
    displayName: string
    fieldType: string
    propertyType: string
    systemType: string
    system: string
    description: string
    jsonPath: string
    expression: string
    status: string
    format: string
    notes: string
    // convertNullToBlank: boolean
    isACollection: boolean
    parentField: string
    parentFieldId: number
    // audit: AuditInfo
    updatedBy: string
    updated: Date

}
export interface FieldSummary {
    id: string
    name: string
    displayName: string
    description: string
    fieldType: string
    propertyType: string
    systemTypeName: string
    jsonPath: string
    expression: string
    isUsedInFormRules: boolean
    isUsedInClosingConditionRules: boolean
    status: string
    format: string
    updatedBy: string
    updated: Date
    createdBy: string
    created: Date
}

export interface FieldDetail {
    id: string | null
    name: string
    displayName: string
    description: string
    fieldType: string
    propertyType: string
    systemTypeName: string
    jsonPath: string
    expression: string
    rules: FieldValueRule[]
    isUsedInFormRules: boolean
    isUsedInHybridPackageRules: boolean
    isUsedInClosingConditionRules: boolean
    status: string
    convertNull: boolean
    isCollection: boolean
    isFlattened: boolean
    isUsedForSignatureDate: boolean
    formatId: number
    readonlyPermissionLabel: string
    //TODO: link to format object
    notes: string
    parentId: number
    //TODO: link to field object
    audit: AuditInfo
    updatedBy: string
}

export interface FieldValueRule {
    value: string
    description: string
    notes: string
    conditions: Condition
}

export enum OperatorType{
    //This is not an exhaustive list of OperatorTypes. It is only used to populate the arrays below.
    Empty = "Empty",
    EmptyOrZero = "EmptyOrZero",
    NotEmpty = "NotEmpty",

    GreaterThan = "GreaterThan",
    LessThan = "LessThan",
    GreaterThanOrEqualTo = "GreaterThanOrEqualTo",
    LessThanOrEqualTo = "LessThanOrEqualTo",
}

export const EmptyOperatorTypes = [OperatorType.Empty, OperatorType.EmptyOrZero, OperatorType.NotEmpty]
export const LessOrGreaterOperatorTypes = [OperatorType.GreaterThan, OperatorType.LessThan, OperatorType.GreaterThanOrEqualTo, OperatorType.LessThanOrEqualTo]

export interface FieldTestDataParams {
    loanId: string
    packageType: string
    field: FieldDetail
}

export interface FieldTestDataResponse {
    type: string
    value: string
}

export interface FieldTestBulkResponse {
    message: FieldTestDataResponse
    fieldId: string
    fieldName: string
}

export enum FieldPropertyType {
    Boolean = 'Boolean',
    Date = 'Date',
    DateTime = 'DateTime',
    Decimal = 'Decimal',
    Integer = 'Integer',
    Object = 'Object',
    String = 'String',
    SystemType = 'SystemType',
}

