export interface Originator {
    originatorType?: OriginatorType
    originatorActingAs?: OriginatorActingAsType
    originatorServicesProvided: string
    dateOriginatorServicesPerformed?: Date
    applicationSignedDate?: Date
    maxInterestRate?: number
    percentOfLoansFundedByOriginator?: number
    servicesProvided: OriginationServicesProvided
    dualCapacity: boolean
    natureOfRelationship: string
    representations: OriginatorRepresentationType[]
    isActingAsAttorney: boolean
    attorneyRepresentations: OriginatorAttorneyRepresentationType[]
}

export enum OriginatorRepresentationType {
    Seller = "Seller",
    Borrower = "Borrower",
    SellerAndBorrower = "SellerAndBorrower"
}

export enum OriginatorAttorneyRepresentationType {
    Seller = "Seller",
    Borrower = "Borrower",
    Lender = "Lender"
}

export enum OriginatorType {
    Lender = "Lender",
    Broker = "Broker",
    Modifier = "Modifier"
}

export enum OriginatorActingAsType {
    YourAgent = "YourAgent",
    IndependentContractorAndNotAsYourAgent = "IndependentContractorAndNotAsYourAgent"
}

export interface OriginationServicesProvided {
    takeInformation: boolean
    analyzeIncome: boolean
    educateBorrower: boolean
    collectInformation: boolean
    verifyEmployment: boolean
    verifyMortgageRentalHistory: boolean
    orderMiscReports: boolean
    provideDisclosures: boolean
    orderCreditReport: boolean
    maintainContact: boolean
    satisfyConditions: boolean
    orderClosingDocs: boolean
    explainCompensation: boolean
    otherServicesProvided: boolean
    otherServicesProvidedExplanation: string
}
