<template>
    <div>
        <h1>
            Conditions
            <custom-icon
                icon="Upload"
                title="Save this configuration as a new rule"
                v-if="saveNewRules"
                class="clickable"
                v-b-modal="'modal-save-as-rule'"
                :disabled="readOnly"
            />
        </h1>
        <div class="field-section">
            <fg-select
                id="packageTypes"
                label="Package Types"
                v-if="!hidePackageTypes"
                size="l"
                :source="allPackages"
                :searchable="false"
                :multiple="true"
                :showEmptyOption="false"
                showSelectedItemsValueOnDisplay
                optionDisplayType="SplitWithValue"
                v-model="conditions.packageTypes"
                :disabled="readOnly"
            />

            <fg-select
                id="rules"
                label="Rules"
                size="l"
                :source="allRules"
                searchable
                use-local-search
                :multiple="true"
                :showEmptyOption="false"
                optionDisplayType="SplitWithSecondaryTextBelow"
                v-model="conditions.ruleIds"
                placeholder="Select rules from the library"
                :disabled="readOnly"
            />
        </div>

        <h2>Field Conditions</h2>
        <rule-criteria-block-list
            v-if="conditions.criteriaItems"
            :openItemsChanged="openItemsChanged" 
            :criteriaItems.sync="conditions.criteriaItems" 
            :field="field" 
            :readOnly="readOnly" 
        />

        <h2>Additional Criteria</h2>
        <div class="field-section">
            <slot name="additional-fields"></slot>
            <div class="field-group" v-if="showDates">
                <fg-date id="activeFrom" v-model="conditions.activationDate" label="Active From: " :restricted="readOnly" />
                <fg-date id="activeTo" v-model="conditions.expirationDate" label="To: " :restricted="readOnly" />
            </div>
            <custom-button v-else label="Set Active Dates" @click="showDates=true" />
        </div>

        <validation-observer v-slot="{ invalid }">
            <b-modal id="modal-save-as-rule" title="Save to Rule Library" @ok="saveAsRule()" :ok-disabled="invalid" ok-title="Save">
                <p>
                    <blurb>
                        <template v-slot:longMessage
                            ><p>
                                The conditions you've configured here will be saved to the rule library as a general-purpose rule, and this
                                {{ displayItemToSave }} will be updated to use that rule.
                            </p>
                            <ul>
                                <li v-show="conditions.activationDate">Active from: {{ formatDate(conditions.activationDate) }}</li>
                                <li v-show="conditions.expirationDate">Expires on: {{ formatDate(conditions.expirationDate) }}</li>
                                <li v-if="conditions.packageTypes ? conditions.packageTypes.length : 0 > 0">Package Types: {{ packageTypeDisplay }}</li>
                                <li v-if="conditions.ruleIds ? conditions.ruleIds.length : 0 > 0">Rules: {{ displayRuleText(conditions.ruleIds) }}</li> 
                                <li v-if="conditions.criteriaItems.length > 0">
                                    Criteria:
                                    <ul>
                                        <div v-for="(criteriaItem, index) in conditions.criteriaItems" :key="`criteriaItem-${index}`">
                                            {{ criteriaItem.fieldName }}&nbsp; <b>{{ criteriaItem.operatorType }}</b
                                            >&nbsp;
                                            {{ getCriteriaItemValueLabel(criteriaItem) }}
                                        </div>
                                    </ul>
                                </li>
                            </ul>
                        </template>
                    </blurb>
                </p>

                <div class="field-section">
                    <fg-text id="name" label="Name" class="required" v-model="ruleName" size="s" validationRules="required" />
                    <fg-text id="description" label="Description" v-model="ruleDescription" size="" />
                </div>
            </b-modal>
        </validation-observer>
    </div>
</template>

<script setup lang="ts" \>
import { adminDataService, messageService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { AuditCheck, Condition, Criteria, FieldDetail, FormRule, Rule, RuleDetail, DefaultRule, ItemsChangedEventArgs, FieldValueRule } from '@/common/models'
import { PackageType } from '@/propel/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { DateHelper } from '@/common/utilities/helper'
import { PropType, computed, ref, defineProps, defineEmits, toRefs } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"openItemsChanged", itemChangedEvent: ItemsChangedEventArgs)
}>()

const props = defineProps({
    conditions: {type: Object as PropType<Condition>, required: true},
    field: {type: Object as PropType<FieldDetail>},
    auditCheck: {type: Object as PropType<AuditCheck>},
    formRule: {type: Object as PropType<FormRule>},
    defaultRule: {type: Object as PropType<DefaultRule|FieldValueRule>},
    saveNewRules: {type: Boolean},
    readOnly: {type: Boolean},
    hidePackageTypes: {type: Boolean}
})

const ruleName = ref("")
const ruleDescription = ref("")
const showDates = ref(false)
const allRules = ref<MultiSelectItem[]>([])
const allPackages = ref<MultiSelectItem[]>([])
const { conditions, auditCheck, formRule } = toRefs(props)
//#endregion

//#region COMPUTED
const displayItemToSave= computed(() => auditCheck?.value ? 'Audit Check' : 'Form Rule')
const packageTypeDisplay = computed(() => conditions.value.packageTypes.join(', '))
//#endregion

//#region INITIALIZE
initialize()

async function initialize() {
    ruleDescription.value = auditCheck?.value?.description ?? formRule?.value?.description ?? props.defaultRule?.description ?? ""
    allRules.value = await getRules()
    allPackages.value = await getPackageTypes()
    showDates.value = !!conditions.value.activationDate || !!conditions.value.expirationDate
}
//#endregion

    async function getPackageTypes() {
        const packageTypes = await adminDataService.getPackageTypes()
        return packageTypes.map((pt: PackageType) => ({ value: pt.code, text: pt.displayName }))
    }

    async function getRules(): Promise<MultiSelectItem[]> {
        const rules = await metadataService.getAllRules()
        return rules.map((r: Rule) => ({ value: r.id, text: r.name, secondaryText: r.description }))
    }

    function openItemsChanged(e: ItemsChangedEventArgs) {
        emit('openItemsChanged', e)
    }

    function displayRuleText(ruleIds: string[]) {
        return ruleIds.map(ruleId => allRules.value.find((x) => x.value === ruleId)?.text).join(", ")
    }

    async function saveAsRule() {
        const newRule = await metadataService.postRule({
            id: null,
            name: ruleName.value,
            description: ruleDescription.value,
            status: 'Active',
            conditions: conditions.value
        } as RuleDetail)

        conditions.value.activationDate = null
        conditions.value.expirationDate = null
        conditions.value.criteriaItems = [] as Criteria[]
        conditions.value.ruleIds = [] as string[]
        conditions.value.packageTypes = [] as string[]
        conditions.value.ruleIds.push(newRule.id as string)
        if (auditCheck?.value) 
            auditCheck.value.conditions = conditions.value as Condition
        if (formRule?.value)
            formRule.value.conditions = conditions.value as Condition

        allRules.value = await getRules()

        ruleName.value = ''
        ruleDescription.value = ''
        messageService.showSaveSuccess(newRule.name)
    }

    function formatDate(date: Date| null): string {
        return date ? DateHelper.formatUTCDateForRoute(date) : ''
    }

    function getCriteriaItemValueLabel(criteriaItem) {
        return criteriaItem.compareExpression ? criteriaItem.compareExpression : criteriaItem.compareValues
    }
</script>

<style lang="scss" scoped>
.field-columns {
    column-gap: unset;

    .column:first-of-type {
        margin-right: 1.5rem;
    }
    .column:last-of-type {
        max-width: 18rem;
        padding: 0.25rem;
    }
}
</style>