<template>
    <b-dropdown
        class="custom-button"
        v-bind:class="{ 'has-left-icon': icon, 'no-icon': !icon, 'no-label': !label }"
        v-if="menuItems || !!$slots.menuItems"
        @click="emit(disabled ? 'noop' : 'click')"
        @shown="focusOnSearch"
        :id="id"
        :split="split"
        :variant="variant"
        :disabled="disabled || loading"
    >
        <template #button-content>
            <span class="content" :title="title" v-b-tooltip.html.hover>
                <custom-icon v-if="loading" icon="Loading" />
                <custom-icon v-else-if="icon" :icon="icon" />
                <span v-if="label" class="label">
                    {{ label }}
                </span>
            </span>
        </template>
        <slot name="menuItems"></slot>
        <list-box
            :id="id"
            ref="listBoxElement"
            v-if="!$slots.menuItems"
            :items="menuItems"
            :disabled-callback="isItemDisabled"
            @click="emit('click', $event)"
            @search="emit('search', $event)"
            searchable
            :clear-search-on-select="clearSearchOnSelect"
            :show-search-always="showSearchAlways"
        >
            <!-- Pass-through slots to allow us to define item templates from grandparent component -->
            <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="data">
                <slot :name="slot" v-bind="data"></slot>
            </template>
        </list-box>
    </b-dropdown>
    <b-button
        v-else
        class="custom-button"
        v-bind:class="{ 'no-label': !label, 'no-icon': !icon }"
        @click.stop="emit(disabled ? 'noop' : 'click')"
        :id="id"
        :variant="variant"
        :disabled="disabled || loading"
        :title="title"
        v-b-tooltip.html.hover
    >
        <span class="content">
            <custom-icon v-if="loading" icon="Loading" />
            <custom-icon v-else-if="icon" :icon="icon" />
            <span v-if="label" class="label">
                {{ label }}
            </span>
        </span>
    </b-button>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, nextTick, PropType, ref } from 'vue'
import { MultiSelectItem } from './multi-select-item'
import ListBox from './list-box.vue'
const emit = defineEmits<{
    (e: 'click' | 'noop', payload?: any): void
    (e: 'search', searchInput: string): void
}>()
//#region DEFINE VARIABLES
const props = defineProps({
    id: { type: String },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    split: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    menuItems: { type: Array as PropType<Array<MultiSelectItem>> },
    icon: { type: String },
    variant: { type: String, default: 'primary' },
    disabled: { type: Boolean, default: false },
    showSearchAlways: {type: Boolean, default: false },
    clearSearchOnSelect: { type: Boolean, default: false }
})

const listBoxElement = ref<InstanceType<typeof ListBox> | null>(null)
//#endregion

function focusOnSearch() {
    nextTick(() => {
        listBoxElement.value?.searchFilterElement?.focus()
    })
}

function isItemDisabled(item: MultiSelectItem) {
    return item.disabled ?? false
}
</script>

<style lang="scss" scoped>
// A non-split dropdown button that has a left icon should not show the arrow on the right
.has-left-icon {
    :deep .dropdown-toggle:not(.dropdown-toggle-split) {
        &::after {
            display: none;
        }
    }
}

:deep {
    .btn,
    .btn {
        height: 1.625rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .dropdown-toggle-split {
        width: 0.875rem;
        padding: 0;
        border-left-color: $light-blue;

        &::after {
            margin-top: 0.625rem;
            font-size: 0.9rem;
        }
    }

    li.wrapper .dropdown-item {
        padding: 0;
        height: 100%;

        &:hover,
        &:active {
            background-color: unset;
            color: unset;
        }
    }
}

button {
    .content {
        // button .content contains an icon and a label
        // display:flex mostly allows for a layout where icon and text both take up
        // defined spaces, but icons don't get centered exactly in their space,
        // so they have a negative margin to adjust slightly

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        .custom-icon {
            width: 0.5rem;
            transform: scale(0.94);

            &.preview {
                transform: rotateZ(90deg);
            }

            &.send {
                margin-left: -0.125rem;
                margin-right: 0.125rem;
            }
        }

        .label {
            min-width: 2rem;
            flex-grow: 1;
        }
    }
    &:hover {
        .custom-icon.close {
            color: $white;
        }
    }
    &.disabled {
        .custom-icon {
            color: $medium-gray;
        }
    }
}

.custom-button {
    // keeps button height and font size consistent regardless of whether
    // it's a plain button or wrapped in a b-dropdown div
    max-height: 1.625rem;

    .label {
        font-size: 0.8125rem;
    }

    .custom-icon:hover {
        color: unset;
    }
}

// display:flex throws the dropdown triangle out of whack
.dropdown.no-icon {
    .content {
        display: inline;

        .label { // something throws button text off center when there's no left icon
            position: relative;
            top: -1px;
        }
    }
}

button.no-label,
.dropdown.no-label button {
    width: 1.75rem;
    .content {
        .custom-icon {
            transform: unset;
        }
    }
}
</style>