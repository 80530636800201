import { Overridable } from "./meta"

export interface PrepaymentPenalty {
    hasPrepaymentPenalty: boolean
    calculationType: string
    balanceType: string
    maxLifetimeAmount: Overridable<number>

    percent: number
    months: number
    optionType: string

    curtailmentPercent: number
    monthsOfInterest: number

    alternateProgram: PrepaymentPenaltyAlternateProgram

    tiers: PrepaymentPenaltyTier[]

    priorPrepaymentPenalty: number
    priorPrepaymentPenaltyIsFinanced: boolean
    priorPrepaymentPenaltyPaidBy: string
    penaltyChargeEligibleOn: ApplicablePrepaymentAmountType | null
}

export interface PrepaymentPenaltyTier {
    percent: number
    months: number
    optionType: string
}

export interface PrepaymentPenaltyAlternateProgram {
    interestRate: number
    points: number
    fees: number
}

export enum ApplicablePrepaymentAmountType {
    Full = "Full",
    Partial = "Partial",
    FullOrPartial = "FullOrPartial"
}