import { PrepaidItem, PrepaidInterest, Payoff, Overridable, DisclosureSectionType, FundType, ProrationItem, DocPrepAttorney, PreparedBy } from '@/common/models'

export interface Closing {
    fundingDate: Overridable<Date>
    closingDate: Date
    rescissionDate: Overridable<Date>
    prepaidInterest: PrepaidInterest
    prepaidItems: PrepaidItem[]
    city: Overridable<string>
    state: Overridable<string>
    county: Overridable<string>
    docPrepAttorney: DocPrepAttorney
    endorsements: string
    additionalEndorsements: string
    preparedBy: Overridable<PreparedBy>
    failureToClose: FailureToClose
    payoffs: Payoff[]
    prorationItems: ProrationItem[]
    closingCostFunds: ClosingCostFund[]
    closingCostsLockedIn: ClosingCostsLockedInType
    businessDaysPriorToClosingCostsLockedIn: number
}

// added interfaces below due to some differences with the admin data model equivalents
// and to avoid name clashes and/or a larger refactor

export interface FailureToClose {
    reason: string
    affectsAvailability: boolean
    newConditions: string
}

export interface ClosingPreparedByAddress {
    street: string
    city: string
    state: string
    zip: string
}

export interface ClosingCostFund {
    fundType: FundType
    fundAmount: number
    sectionType: DisclosureSectionType
}

export enum ClosingCostsLockedInType {
    ApplicationDate = "ApplicationDate",
    RateLockAgreement = "RateLockAgreement",
    PriorToClosingDate = "PriorToClosingDate"
}