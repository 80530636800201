<template>
    <div class="list-box-container">
        <div v-if="searchable && (items.length >= config.app.minSearchableCount || showSearchAlways)" class="list-box-search sticky">
            <search-input :id="`filter-${id}`" ref="searchFilterElement" v-model="searchFilter" @input="emit('search', $event)" :debounceTime="300" />
        </div>
        <b-dropdown-item class="list-box-item" :variant="item.variant ?? null" v-for="(item, index) in filteredItems" :key="index" @click="click(item)" :disabled="itemIsDisabled(item)">
            <div class="list-box-primary-content">
                <slot name="primary-content" v-bind="item">{{ item.text }}</slot>
            </div>
            <div class="list-box-secondary-content">
                <slot name="secondary-content" v-bind="item">{{ item.secondaryText }}</slot>
            </div>
        </b-dropdown-item>
        <b-dropdown-item class="list-box-item no-items" v-if="!filteredItems?.length">
            <div class="list-box-primary-content">
                <span>No items found</span>
            </div>
        </b-dropdown-item>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, PropType, defineEmits, defineProps, defineExpose } from 'vue'
import { get } from 'lodash'
import { config } from '@/config'
import SearchInput from './search-input.vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'click', payload: any): void 
    (e: 'search', searchInput: any): void
}>()
const props = defineProps({
    id: { type: String },
    items: {
        type: Array as PropType<Array<any>>,
        default() {
            return [] as any[]
        }
    },
    searchable: { type: Boolean, default: false },
    clearSearchOnSelect: { type: Boolean, default: false },
    filterOn: {
        type: Array as PropType<Array<string>>,
        default() {
            return [] as string[]
        }
    },
    disabledCallback: {
        type: Function as PropType<(item) => boolean>
    },
    showSearchAlways: {type: Boolean, default: false },
})

const searchFilter = ref<string>('')
const searchFilterElement = ref<SearchInput | null>(null)

//Expose searchFilterElement ref to parent to use for focus in dropdowns
defineExpose({ searchFilterElement })

//#endregion

//#region COMPUTED
const filteredItems = computed(() => {
    if (!props.searchable || !searchFilter.value) return props.items

    const searchFilterValue = searchFilter.value.toLowerCase()
    if (props.filterOn.length > 0) {
        return props.items.filter((x) => props.filterOn.some((c) => get(x, c, '')?.toLowerCase().includes(searchFilterValue)))
    }

    //thow an error if no filterOn defined and default text/secondaryText not used
    if (props.items.length > 0 && !props.items.some((i) => i.text || i.secondaryText)) {
        throw new Error('The items prop must have text or secondaryText properties to search on if the filterOn prop is not valued.')
    }

    //default is assumed to be of type multiselect item
    return props.items.filter((x) => x.text?.toLowerCase()?.includes(searchFilterValue) || x.secondaryText?.toLowerCase()?.includes(searchFilterValue))
})
//#endregion

function click(item) {
    if(itemIsDisabled(item)) return
    
    if (props.clearSearchOnSelect) {
        searchFilterElement.value?.clearSearch()
    }
    emit('click', item)
}

function itemIsDisabled(item) {
    if(!props.disabledCallback) return false

    return props.disabledCallback(item)
}
</script>

<style lang="scss" scoped>
.list-box-container {
    > .list-box-search {
        padding: 0.5rem;

        :deep(.custom-icon) {
            margin: 0;
        }

        // TODO: instead of sticky, would be better to have:
        // search-box
        // list-items
        //    list-box-item
        //    list-box-item
        // ... with search-box and list-items inside a flex-box that expands to fill its parent
        // But the parent in this case only has a max height, not a height, so that doesn't work
        &.sticky {
            position: sticky;
            top: -0.5rem;
            background-color: $white;
        }
    }
    > .list-box-item {
        cursor: pointer;
        font-weight: normal;

        &.no-items {
            cursor: default;
        }

        :deep .dropdown-item {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;

            .list-box-primary-content {
                // icons can be displayed next to items like:
                // <template #primary-content="item">
                //   <custom-icon :icon="item.value" />
                //   <span>
                //     {{ item.text }}
                //   </span>
                // </template>
                display: flex;
                align-items: center;
                gap: 0.5rem;
                line-height: 165%;

                .custom-icon {
                    margin-top: -0.125rem; // adjusts for some line-height or other vertical spacing
                }
            }

            .list-box-secondary-content {
                font-size: 0.6875rem;
                color: $dark-gray;
                line-height: 135%;
            }
        }

        &.no-items {
            font-style: italic;
            &:hover,
            :deep .dropdown-item:hover {
                background: unset;
                cursor: default;
            }
        }
    }
}
</style>