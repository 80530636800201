<template>
    <content-block-list
        id="criteriaItems"
        :listItems.sync="vmCriteriaItems"
        deletable
        :onAdd="addCriteria"
        :addLabelText="addLabelText"
        :size="size"
        closeText="Save"
        :editMode="ContentBlockEditModeEnum.SaveCancel"
        @itemEditing="editCriteria"
        @openItemsChanged="toggleEdit"
    >
        <template v-slot:view="slotParams">
            <div class="standard-columns">
                <div class="wrap display-values" :title="displayValue(slotParams.item) ?? ''">{{ displayValue(slotParams.item) }}</div>
            </div>
        </template>
        <template v-slot:edit="slotParams">
            <div class="field-operator">
                <input-select
                    v-if="fieldSource"
                    id="criteriaField"
                    :source="fieldSource"
                    :useLocalSearch="true"
                    v-model="slotParams.item.fieldName"
                    class="field-name"
                    :multiple="false"
                    :searchable="true"
                    :showEmptyOption="false"
                    placeholder="Select Field"
                    @input="selectCriteria(slotParams.item, true)"
                    optionDisplayType="SplitWithSecondaryTextBelow"
                    :disabled="readOnly"
                />
                <input-select
                    v-else
                    id="criteriaField"
                    :source="getCriteriaFields"
                    v-model="slotParams.item.fieldName"
                    class="field-name"
                    :multiple="false"
                    :searchable="true"
                    :showEmptyOption="false"
                    placeholder="Select Field"
                    optionDisplayType="SplitWithSecondaryTextBelow"
                    @input="selectCriteria(slotParams.item, true)"
                    :disabled="readOnly"
                />
                <input-select
                    id="criteriaOperator"
                    class="operator-type"
                    :key="`${currentFieldName}-operator`"
                    :source="getFieldOperators"
                    v-model="slotParams.item.operatorType"
                    :multiple="false"
                    :searchable="false"
                    placeholder="Operator"
                    :showEmptyOption="false"
                    :disabled="readOnly"
                    @input="updateOperatorType(slotParams.item)"
                    size="s"
                />
            </div>
            <field-value
                :allowMultiple="allowMultipleCompareValues"
                id="defaultFieldValue"
                :fieldName="slotParams.item.fieldName"
                :compareExpression.sync="slotParams.item.compareExpression"
                :compareValues.sync="slotParams.item.compareValues"
                :enableFieldExpression="true"
                :disableWrappedExpressions="true"
                class="field-value"
            />
        </template>
    </content-block-list>
</template>

<script setup lang="ts">
import { referenceService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { Criteria, CriteriaOperator, FieldDetail, FieldSummary, OperatorType, Reference, ReferenceType, ReferenceSource, EmptyOperatorTypes, ItemsChangedEventArgs, ContentBlockEditModeEnum, LessOrGreaterOperatorTypes } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { PropType, computed, ref, defineEmits, defineProps } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "openItemsChanged", itemChangedEvent: ItemsChangedEventArgs)
    (e: "update:criteriaItems", items: Criteria[])
}>()

const props = defineProps({
    criteriaItems: {type: Array as PropType<Array<Criteria>>, required: true},
    fieldSource: {type: Array as PropType<Array<MultiSelectItem>>},
    field: {type: Object as PropType<FieldDetail>},
    readOnly: { type: Boolean },
    size: {type: String, default: "l"},
    addLabelText: {type: String, default: "Add Condition"},
    enableFieldExpression: {type: Boolean, default: true},
    getFields: { type: Function as PropType<(searchTerm: string) => MultiSelectItem[]>},
    displayFunction: { type: Function as PropType<(item: Criteria) => string>},
    onlyHybridPackageRuleFields: { type: Boolean }
})

const allOperators = ref<MultiSelectItem[]>([])

const currentFieldName = ref("") 
const currentOperator = ref("") 
const selectedFieldPropertyType = ref("")

const isCriteriaFieldSystemType = ref(false)
const allowMultipleCompareValues = ref(true)
const loadingDefaults = ref(false)
//#endregion

//#region COMPUTED
const vmCriteriaItems = computed({
    get() {
        return props.criteriaItems
    },
    set(items: Criteria[]) {
        emit('update:criteriaItems', items)
    }
})  

const currentField= computed(() => props.field ? 'This Field' : '')
//#endregion

//#region INITIALIZE
initialize()

async function initialize() {
    allOperators.value = await getOperators()
}
//#endregion

async function getFieldFirstOperator(fieldName: string) {
    if (fieldName?.length > 0) {
        const criteria = await metadataService.getFieldCriteria(fieldName)
        return criteria.operators[0].type
    }
    return ''
}

async function getOperators(): Promise<MultiSelectItem[]> {
   return await referenceService.getMultiSelectItems(ReferenceType.criteriaOperatorTypes, ReferenceSource.metadata)
}

function addCriteria() {
    return {
        fieldName: props.field?.name || currentField.value,
        operatorType: '',
        compareExpression: '',
        compareValues: [] as any[]
    } as Criteria
}

async function editCriteria(criteriaItem: Criteria) {
    loadingDefaults.value = true
    currentFieldName.value = criteriaItem.fieldName
    currentOperator.value = criteriaItem.operatorType
    await selectCriteria(criteriaItem, false)
    loadingDefaults.value = false
    toggleEdit({ criteriaItem, openItemsCount: 1 } as ItemsChangedEventArgs)
}

function updateOperatorType(criteria: Criteria) {
    currentOperator.value = criteria.operatorType
    if (EmptyOperatorTypes.includes(criteria.operatorType as OperatorType)) {
        criteria.compareValues = []
        criteria.compareExpression = ''
    }
    updateCompareValueLimit(criteria)
}

function updateCompareValueLimit(criteria: Criteria) {
    allowMultipleCompareValues.value = !LessOrGreaterOperatorTypes.includes(criteria.operatorType as OperatorType)
    if (!allowMultipleCompareValues.value && criteria.compareValues.length > 1)
        criteria.compareValues = [criteria.compareValues[0]]
}

async function selectCriteria(criteria: Criteria, clearResults) {
    currentFieldName.value = criteria.fieldName
    isCriteriaFieldSystemType.value = false
    selectedFieldPropertyType.value = ''
    const fieldKey = props.field?.name && criteria.fieldName === props.field?.name ? props.field.name : criteria.fieldName
    if (!fieldKey) return
    try {
        const criteriaField = await metadataService.getField(fieldKey)
        const currentFieldOperator = await getFieldFirstOperator(fieldKey)
        selectedFieldPropertyType.value = criteriaField.propertyType
        if (clearResults) {
            criteria.compareExpression = ''
            criteria.compareValues = []
            criteria.operatorType = currentFieldOperator
        } else {
            criteria.operatorType = criteria?.operatorType || currentFieldOperator
        }
        updateCompareValueLimit(criteria)
    } catch (ex: any) {
        console.error(`Error loading Field '${fieldKey}':`, ex)
    }
}

function canAssignCriteriaValueCriteria(criteria: Criteria) {
    return !EmptyOperatorTypes.includes(criteria.operatorType as OperatorType)
}

function getCriteriaItemValueLabel(criteriaItem: Criteria) {
    return canAssignCriteriaValueCriteria(criteriaItem)
        ? criteriaItem.compareExpression
            ? getExpressionLabel(criteriaItem)
            : criteriaItem.compareValues?.join(', ')
        : ''
}

function getExpressionLabel(criteriaItem: Criteria) {
    if (criteriaItem.compareExpression.includes('\r\n') || criteriaItem.compareExpression.includes('<')) return '[ Expression ]'
    else return criteriaItem.compareExpression
}

function getOperatorValueLabel(operatorValue) {
    return allOperators.value.find((x) => x.value === operatorValue)?.text
}

async function getCriteriaFields(searchTerm?: string): Promise<MultiSelectItem[]> {
    if (props.getFields) {
        return props.getFields(searchTerm ?? "")
    }
    const params = new URLSearchParams()

    params.append('pageSize', '25')

    if (props.onlyHybridPackageRuleFields) {
        params.append('IsUsedInHybridPackageRules', 'true')
    } else if (!props.field) {
        params.append('isUsedInFormRules', 'true')
        params.append('name', searchTerm ? '~' + searchTerm.toLowerCase() : currentFieldName.value ? currentFieldName.value : '')
    } else {
        params.append('name', searchTerm ? '~' + searchTerm.toLowerCase() : '')
    }

    
    const fields = await metadataService.getFields(params)
    const mappedFields = fields.items.map((f: FieldSummary) => ({ value: f.name, text: f.name, secondaryText: f.displayName })) as MultiSelectItem[]
    return props.field
        ? [
                ...([currentField.value].map((x) => ({ value: props.field?.name, text: x, secondaryText: props.field?.displayName })) as MultiSelectItem[]),
                ...mappedFields
            ]
        : mappedFields
}

async function getFieldOperators(): Promise<MultiSelectItem[]> {
    if (currentFieldName.value) {
        const criteria = await metadataService.getFieldCriteria(currentFieldName.value)
        return criteria.operators.map((f: CriteriaOperator) => ({ value: f.type, text: f.displayValue } as MultiSelectItem))
    } else {
        return [] as MultiSelectItem[]
    }
}

function displayValue(criteria: Criteria) {
    if (!criteria.fieldName) return null
    if (props.displayFunction) return props.displayFunction(criteria)
    return `${criteria.fieldName} ${getOperatorValueLabel(criteria.operatorType)} ${getCriteriaItemValueLabel(criteria)}`
}

function toggleEdit(e: ItemsChangedEventArgs) {
    emit('openItemsChanged', e)
}
</script>

<style lang="scss" scoped>
.code-input {
    padding-left: 0;
}
.field-operator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .field-name {
        margin-right: 0.5em;
        flex-grow: 1;
        width: 0;

        :deep(.selected) {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .operator-type {
        width: 10em;
    }
}

.display-values {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.input-switch {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &.list-items {
        align-items: baseline;
    }
    &.expression {
        align-items: baseline;

        .form-group.col {
            padding-right: 0;
        }
    }

    .input-container {
        flex-grow: 1;
    }
}

:deep .content-block-list {
    margin-bottom: 0 !important;

    .content-block-items {
        margin-bottom: 0 !important;
    }

    .content-block {
        .content-area {
            padding: 0.25rem 0.125rem !important;
            min-height: 2.25rem !important;
        }
    }
}
</style>